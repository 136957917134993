import Vue from 'vue'
import App from './App.vue'
import router from './router'
import  'vue-material-design-icons/styles.css'
import i18n from './i18n'
import api from './request/api'
import store from './store';
Vue.prototype.$api = api
Vue.config.productionTip = false

new Vue({
  router,
  i18n, 
  store ,
  render: h => h(App),
}).$mount('#app')