<template>
  <div class="home">
    <div class="navbar">
      <button @click="activeTab = 'pvp-list'" :class="{ active: activeTab === 'pvp-list' }">PVP</button>
      <button @click="activeTab = 'create-pvp'" :class="{ active: activeTab === 'create-pvp' }">Create</button>
    </div>
    <div class="container"  v-show="activeTab === 'pvp-list'"> 
      <PairInfo  v-show="isVisible" class="show-pair-info"/>
      <div class="trading-container">
        <div class="pvp-headers">
          <div class="pvp-headers-pvp"> {{ $t('pvp')}} </div>
          <div class="pvp-headers-conquer">{{ $t('Conquer everything')}}  </div>
          <div class="pvp-headers-icons""> 
            <ScaleBalance @click="showPairInfo" :class="{'scaleBalance-icon': true, 'active': isVisible}" />
            <Cog class="cog-icon"/>
          </div>
        </div>
        <div v-if="isDropdownOpen" class="overlay" @click="closePopup">
            <div class="popup" @click.stop>
              <div class="popup-header">
                <span class="selectToken">{{ $t('Select a pvp')}}</span>
                <mdiClose @click="closePopup" class="close-button" />
              </div>
              <div class="search-input-container">
                <input v-model="searchQuery" type="text" placeholder="" class="search-input" @keyup.enter="fetchData"/>
                
              </div>
              <div>{{ $t('Common Pvps') }}</div>
              <ul class="dropdown-list">
                <li v-for="(token, index) in tokens" :key="index" @click="selectToken(token)" class="dropdown-item">
                  <span class="tokens_li">{{ stringShort(token.tokenName) }}</span>
                
                </li>
              </ul>
              <div v-if="searchResult" class="search-result" @click="selectSearchResult(searchResult)">
                  <div class="search-result-item" >
                    {{ searchResult}}
                  </div>
                </div>
                <div v-else class="no-result">
                  {{ 'No results found' }}
                </div>
            </div>
          </div>
        <!-- Token Selector -->
        <div class="token-selector">
          <div class="token-input">
            <div class="token-button" >
                <div class="token-button-left" @click="toggleDropdown">
                  <span>{{ pvpContract ? pvpContract.name : 'Select a Token' }}</span>
                  <ChevronDown class="chevron-down" />
                </div>
                
                <div class="token-button-right" @click="useMaxBalance">
                  <span>{{ uBalance }}</span>
                </div>
            </div>
          
            <div class="input-wrapper">
              <input v-model="tokenAmount" @input="validateInput" type="text" placeholder="0.0" class="search-input"  />
            </div>
          </div>
        </div>
        <!-- Swap Button -->
        <div v-if="isWalletConnected">
          <div v-if="isEnd">
            <button  :disabled="isDraw" @click="DrawToken" class="swap-button">{{ $t('Draw') }}</button>
          </div>
          <div v-else>
            <button v-if="isAuthorized" @click="joinRed" class="swap-button-red">{{ $t('Join') }} {{ pvpContract ? ' red' : null }}</button>
            <button v-if="isAuthorized" @click="joinBlue" class="swap-button-blue">{{ $t('Join') }} {{ pvpContract ? ' blue' : null }}</button>
            <button v-else @click="authorizeToken" class="swap-button">{{ $t('approve') }}</button>
           
          </div>
        <button  @click="goToSwap" class="swap-button">{{ $t('SWAP') }}</button>
      </div>
      <button v-else @click="connectWallet" class="connect-button">Connect</button>
        
      </div>
    
    </div>
    <Create v-show="activeTab === 'create-pvp'" class="create-template" />
  </div>
  
</template>

<script>
import mdiClose from 'vue-material-design-icons/Close.vue';
import ChevronDown from 'vue-material-design-icons/ChevronDown.vue';
import ScaleBalance from 'vue-material-design-icons/ScaleBalance.vue';
import Cog from 'vue-material-design-icons/Cog.vue';
import { mapActions, mapGetters } from 'vuex';
import PairInfo from './PairInfo.vue';
import Create from './Create.vue';
import web3j from '@/js/web3j.js';
export default {
  name: 'Pvp',
  components: {
    ChevronDown,
    mdiClose,
    ScaleBalance,
    Cog,
    PairInfo,
    Create
  },
  data() {
    return {
      isDropdownOpen: false,
      searchQuery: '',
      tokens: [],
      searchResult: null, 
      searchPerformed: false,
      tokenAmount: '',
      uBalance: 0,
      isAuthorized: false,
      activeTab: 'pvp-list',
      isEnd:false,
      isDraw:false
    }
  },
  computed: {
    ...mapGetters(['isWalletConnected','isVisible','pvpContract','chainId','walletAddress','network'])
  },
  methods: {
    ...mapActions(['connectWallet','setPvpToken','setVisible']),
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen
    },
    useMaxBalance(){
      this.tokenAmount=this.uBalance;
    },
    closePopup() {
      this.isDropdownOpen = false
    },
    async  selectToken(token) {
     const  pvpToken=await web3j.getPair(this.network.contract,token.ca);
     const pvp_token= await web3j.getToken(pvpToken);
     const token_name= await web3j.getName(pvp_token);
     const pvp_contract={
       token:pvp_token,
       name:token_name,
       contract:pvpToken
     }
      this.isDropdownOpen = false
      this.setPvpToken(pvp_contract)
      await this.checkAuthorization();
      await this.getUbalance();
      await this.checkIsEnd();
      await this.getIsDraw();
    },
    async  selectSearchResult(pairCa){

      this.isDropdownOpen = false
      const pvp_contract={
        token:"",
        name:"",
        contract:"",
      }
      pvp_contract.token=  await web3j.getToken(pairCa);
      pvp_contract.name= await web3j.getName(pvp_contract.token);
      pvp_contract.contract=this.searchResult;
      this.setPvpToken(pvp_contract)
      await this.checkAuthorization();
      await this.getUbalance();
      await this.checkIsEnd();
      await this.getIsDraw();
    },
    async checkAuthorization() {
      try{
        if (this.pvpContract && this.walletAddress) {
          const allowanceAmount = await web3j.allowance(this.pvpContract.token, this.pvpContract.contract, this.walletAddress);
          if(allowanceAmount>0){
          this.isAuthorized =true;
          }else{
            this.isAuthorized =false;
          }
          console.log(this.tokenAmount);
          if(this.tokenAmount&&this.tokenAmount>0){
            const  decimals= await web3j.decimals(this.pvpContract.token);
            const BigIntValue= await web3j.convertToBigInt(this.tokenAmount,decimals);
            if(allowanceAmount>BigIntValue){
              this.isAuthorized =true;
            }else{
              this.isAuthorized =false;
            }
          }
          
        }
      }catch(error){
        this.isAuthorized =false;
      }  
    },
    authorizeToken() {
      if (this.pvpContract && this.walletAddress) {
        web3j.approve(this.pvpContract.token,this.pvpContract.contract, this.walletAddress)
          .then(() => {
            this.isAuthorized = true; // 授权成功后更新状态
          })
          .catch(error => {
            this.isAuthorized = false;
            console.error("Authorization failed:", error);
          });
      }
    },
    joinRed() {
      if(this.tokenAmount==0){
          return;
      }
      if (!this.pvpContract) {
        alert('Please select a token')
        return
      }
      web3j.joinRed(this.pvpContract.contract,this.tokenAmount,this.walletAddress);
      
      
    },
    joinBlue() {
      if(this.tokenAmount==0){
          return;
      }
      if (!this.pvpContract) {
        alert('Please select a token')
        return
      }
      web3j.joinBlue(this.pvpContract.contract,this.tokenAmount,this.walletAddress);
     
    },
    goToSwap(){
      this.network;
      let url;
      if(this.pvpContract){
        if(this.network.chainId=="0x1"){
        url  = this.network.swap;
      }else{
        url  = this.network.swap+this.pvpContract.contract;
      }
      window.open(url, '_blank');
      }
      
     
    },
    async DrawToken(){
      try{
        await web3j.getReward(this.pvpContract.contract,this.walletAddress);
      }catch(error){
        
      }
    },
    async checkIsEnd(){
      if(this.pvpContract){
        this.isEnd= await web3j.getIsEnd(this.pvpContract.contract);
      }
    },
    async getIsDraw(){
      try{
        if(this.pvpContract){
          const joinAmount = await web3j.getJoinAmount(this.pvpContract.contract,this.walletAddress);
        if(joinAmount){
        const redVictory=  await web3j.redVictory(this.pvpContract.contract);
        if(redVictory&&joinAmount.redAmount>0){
         const  pBalance=await web3j.balanceOf(this.pvpContract.contract,this.walletAddress);
          this.isDraw= pBalance==0;
        }
        if(!redVictory&&joinAmount.blueAmount>0){
          const  pBalance=await web3j.balanceOf(this.pvpContract.contract,this.walletAddress);
          this.isDraw= pBalance==0;
        } 
        }
        }
        
      
      }catch(error){
        this.uBalance = 0;
      }
    },
    async fetchTokens() {
      try {
        this.tokens = this.network.tokens;
      } catch (error) {
        console.error('Error fetching tokens:', error);
      }
    },
    async fetchData(){
      if(this.searchQuery){
        const allowanceAmount = await web3j.getPair(this.network.contract,this.searchQuery);
        this.searchResult = allowanceAmount;
        if(allowanceAmount){
          this.searchPerformed = true; 
        }
       
      }
      
    }
    ,
    async getUbalance(){
      try{
        if(this.walletAddress&&this.pvpContract){
         await web3j.balanceOf(this.pvpContract.token,this.walletAddress).then(res=>{
          this.uBalance=res;
        })
      }else{
        this.uBalance=0;        
      }
      }catch(error){
        this.uBalance=0;
      } 
    }
    ,
    stringShort(str) {
      if (!str) return '';
      if(str.length<=5) return str;
      const start = str.slice(0, 3);
      const end = str.slice(-1);
      return `${start}*${end}`;
    },
    async  validateInput(event) {
      let value = event.target.value;
      
      if (value.startsWith('.')) {
        value = '0' + value;
      }
     
      const parts = value.split('.');
      if (parts.length > 2) {
        value = parts[0] + '.' + parts.slice(1).join('');
      }
    
      value = value.replace(/[^0-9.]/g, '');
    
      this.tokenAmount = value;
      const allowanceAmount = await web3j.allowance(this.pvpContract.token, this.pvpContract.contract, this.walletAddress);
      const  decimals= await web3j.decimals(this.pvpContract.token);
     const BigIntValue= await web3j.convertToBigInt(this.tokenAmount,decimals);
      if(allowanceAmount>BigIntValue){
        this.isAuthorized =true;
      }else{
        this.isAuthorized =false;
      }
    },
    showPairInfo() {
      this.setVisible(!this.isVisible);
    },
    setInitialPairInfoVisibility() {
      const mediaQuery = window.matchMedia('(max-width: 768px)');
      this.setVisible(!mediaQuery.matches);
      mediaQuery.addListener((e) => {
        this.setVisible(!e.matches);
      });
    }
  },
  mounted() {
    this.setInitialPairInfoVisibility();
    this.checkAuthorization();
    this.checkIsEnd();
    this.getIsDraw();
      this.scoreInterval = setInterval(() => {
        if(!this.isEnd){
          if(!this.isDraw){
            this.getIsDraw();
          }
          this.checkIsEnd();
        }
      }, 3000);
      this.isAuthorizedInterval = setInterval(() => {
        if(!this.isAuthorized){
          this.checkAuthorization();
        }
      }, 3000);
      this.getBalance=setInterval(() => {

        this.getUbalance();

      }, 3000);

    
  },
  beforeDestroy() {
    clearInterval(this.scoreInterval);
  clearInterval(this.isAuthorizedInterval);
  clearInterval(this.getBalance);
  },
  created(){
    this.fetchTokens()
  },
  watch: {
 
    walletAddress(newAddress, oldAddress) {
      if (!newAddress) {
        this.uBalance = 0;
      } else if (newAddress !== oldAddress) {
        this.getUbalance();
      }
    },
    isWalletConnected(isConnected) { 
      if (!isConnected) {
        this.uBalance = 0; 
      } else {
        this.getUbalance();
      }
    },
    async   chainId(newChainId, oldChainId){
       if (newChainId !== oldChainId) {
        await  this.fetchTokens();
      }
    }
  },

}
</script>

<style scoped>
.home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin-top: 75px;
}

.trading-container {
  width: 300px;
  height: 500px;
  background: #726785;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: left; /* Align text to the left */
  margin-top: 100px;
}

.pvp-headers {
  height: 80px; /* Adjusted height to auto */
  border-bottom: 2px solid rgb(9, 236, 9);
  width: 100%; 
  margin-left: -20px; 
  padding: 20px;
}
.pvp-headers-pvp{
  font-size: 20px;
  font-weight: bold;
  margin-left: 30px;
}
.pvp-headers-conquer{
  font-size: 15px;
  margin-left: 30px;
  margin-top: 15px;
  color: #e9c9c9;
}

.token-selector {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
}

.token-input {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.token-button {
  color: #240303;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
  justify-content: space-between;
  display: flex;
  align-items: center;
  font-size: 25px;
  font-weight: bold;
}
.token-button-right {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 13px;
}



.popup {
  position: fixed; /* Fixed positioning to stick to the viewport */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust to center the popup */
  background: #fff;
  border-radius: 40px;
  box-shadow: 0 2px 10px rgb(0, 0, 0);
  z-index: 1000; /* Ensure it is above other content */
  width: 400px;
  height: 550px; /* Adjust height based on your needs */
  overflow: hidden; /* Hide scrollbars */
  padding: 20px; 
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  height: 60px;
  border-bottom: 2px solid rgb(197, 202, 197); /* Green border line */
  padding-bottom: 10px; /* Add padding below the line */
}

.close-button {
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.search-input {
  width: 100%; /* Adjust width as needed */
  height: 40px;
  padding: 10px;
  border: 1px solid #ece4f7;
  border-radius: 15px;
  margin-bottom: 10px;
  background-color: #EEEAF4; /* Light gray background */
}

.search-input-container {
  display: flex;
  justify-content: center;
}

/* Hide scrollbar for Webkit browsers */
.dropdown-list::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.dropdown-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-height: 200px;
  overflow-y: auto;
  justify-content: center; 
  margin-right: 50px;
}
.dropdown-item {
   padding: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column; /* 垂直排列 */
  align-items: center; /* 居中对齐 */
  border: 1px solid #ddd;
  border-radius: 10px;
  width: calc(25%); /* 宽度调整以适应每行3个 */
  justify-content: center;
  word-wrap: break-word; /* 自动换行 */
  text-align: center;
  background-color: #cbe7b0;
}

.dropdown-item:hover {
  background: #f1f1f1;
}

.token-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.token-input input {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 93%;
}
.connect-button {
  width: 100%;
  height: 60px;
  background: linear-gradient(90deg, #ff7a18, #af002d 70%);
  color: #fff;
  border: none;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}
.swap-button {
  width: 94%;
  height: 60px;
  background: linear-gradient(90deg, #ff7a18, #210683 70%);
  color: #fff;
  border: none;
  padding: 15px;
  margin-left: 10px;
  margin-top: 15px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}
.swap-button-red {
  width: 45%;
  margin-left: 10px;
  background: linear-gradient(90deg, #ff7a18, #920a2c 70%);
  color: #fff;
  border: none;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}
.swap-button-blue {
  width: 45%;
  margin-left: 10px;
  background: linear-gradient(90deg, #1f66c4, #350a49 70%);
  color: #fff;
  border: none;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}
.swap-button:hover {
  opacity: 0.9;
}
.selectToken {
  font-size: 20px;
  font-weight: 700;
}
.tokens_li {
  font-size: 15px;
   text-align: center; /* 文本居中对齐 */
  width: 100%; /* 占据全宽 */
}
.pvp-headers-icons{
  float: right;
  margin-right: 30px;
  margin-top: 10px;
}
.scaleBalance-icon {
  cursor: pointer;
  color: #6c797a;
  font-size: 1.5em;
  margin-right: 10px;
}
.scaleBalance-icon.active {
  color: rgb(10, 12, 141); 
}

.cog-icon{
  cursor: pointer;
  font-size: 1.5em;
}
.show-pair-info {
  height: 540px;
  width: 700px;
  margin-right: 30px;
  margin-top: 100px;
}
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center; /* 添加此行以使内容居中 */
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 7, 5, 0.5);
  z-index: 999; /* Ensure overlay is below the popup */
}
.navbar {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #c8eff8;
}

.navbar button {
  background: none;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  transition: color 0.3s;
}

.navbar button.active {
  color: #3d0d96;
  font-weight: bold;
  border-bottom: 4px solid #1e8bca;
}

.navbar button:not(.active):hover {
  color: #20b6f1;
}
.create-template {
  width: 45%; /* 或者你想要的宽度 */
  height: 300px; /* 或者你想要的高度 */
  background-color: #f0f0f0; /* 临时背景色，用于测试 */
  margin-top: 100px;
}
.search-result {
  height: 50px; /* 固定高度 */
  margin-top: 100px; /* 距顶部的间距 */
  padding: 8px; /* 内边距 */
  background-color: #5c9dbb; /* 背景颜色 */
  font-size: 15px; /* 字体大小 */
  border-radius: 8px; /* 圆角半径 */
  display: flex; /* 使用 Flexbox 布局 */
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  color: #fff; /* 文字颜色 */
  text-align: center; /* 文字居中对齐 */
  cursor: pointer;
}

.swap-button:disabled {
  cursor: not-allowed;
  opacity: 0.1;
}

.no-result {
  margin-top: 100px;
  margin-left: 70px;
  color: #999;
  font-size: 30px;
}
@media (max-width: 768px) {
  .trading-container {
    width: 90%;
  }
.token-icon {
  width: 20px; /* 根据需要调整图标大小 */
  height: 20px;
  margin-right: 10px; /* 根据需要调整间距 */
}
.popup {
  width: 90%; /* Width set to 90% */
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    top: auto; /* Ensure it is aligned from the bottom */
    max-height: 80%; /* Adjust to ensure it fits within the screen height */
    overflow-y: auto; /* Allow vertical scrolling if needed */
    animation: slideUp 0.3s ease-out;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 7, 5, 0.5);
  z-index: 999;
  }
  .swap-button {
  width: 93%;
  }
.show-pair-info {
    position: fixed;
    bottom: 0;
    left: 50%;
    height: 650px;
    transform: translateX(-50.1%);
    width: 100%;
    margin: 0;
    border-radius: 20px 20px 0 0;
    display: block;
    animation: slideUp 0.3s ease-out;
    z-index: 1001; /* 确保层级在 overlay 之上 */
  }
@keyframes slideUp {
    from {
      transform: translateY(100%) translateX(-50%);
      opacity: 0;
    }
    to {
      transform: translateY(0) translateX(-50%);
      opacity: 1;
    }
  }
.create-template {
  width: 90%; /* 或者你想要的宽度 */
  height: 300px; /* 或者你想要的高度 */
}
}

</style>
