<template>
  <div class="contracts_list">
    <div class="pvp-headers">
      <div class="list-headers">
        <div class="input-container">
          <SearchIcon class="search-icon" />
          <input
            v-model="searchQuery"
            type="text"
            placeholder="Search..."
            class="search-input"
            @keyup.enter="fetchData"
          />
          <button class="search-button" @click="fetchData">{{ $t('Search') }}</button>
        </div>
      </div>
      <div class="data-list">
        <div v-if="pairInfo.name" class="data-name">{{ pairInfo.name }}</div>
        <button
            v-if="pairInfo.name"
            class="action-button"
            @click="goToPvp(pairInfo.ca)"
        >
          {{ 'PVP' }}
        </button>
      </div>

    </div>
  </div>
</template>

<script>
import SearchIcon from 'vue-material-design-icons/Magnify.vue';
import { mapActions , mapGetters} from 'vuex';
import web3j from '@/js/web3j.js';
export default {
  name: 'PairInfo',
  components: { SearchIcon },
  data() {
    return {
      searchQuery: '',
      pairInfo: {
        name:"",
        ca:""
      },
    };
  },
  computed: {
    ...mapGetters(['chainId','network']),

  },
  methods: {
    ...mapActions(['setPvpToken']),
    async fetchData() {

      try {
        if(this.searchQuery&&this.network.contract){
          let pvpToken  = await web3j.getPair(this.network.contract,this.searchQuery);
          if(!pvpToken){
           const  pvp_token=await web3j.getToken(this.searchQuery);
           if(pvp_token){
            pvpToken=this.searchQuery;
           }
         }
          if(pvpToken){
            this.pairInfo.ca=pvpToken;
            const token_pvp=await web3j.getToken(pvpToken);
            this.pairInfo.name=await web3j.getName(token_pvp);
          }


        }
      } catch (error) {
        console.error('Error fetching tokens:', error);
      }
    },
    async   goToPvp(ca) {
      if (ca) {   
     const pvp_token= await web3j.getToken(ca);
     const token_name= await web3j.getName(pvp_token);
        const pvp_contract={
       token:pvp_token,
       name:token_name,
       contract:ca
     }
        this.setPvpToken(pvp_contract);
      }
      this.$router.push('/pvp');
    },
    showAlert() {
      alert("Already claimed!");
    },
  },
  watch: {
    chainId: {
      immediate: true, 
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.fetchData();
        }
      },
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
<style scoped>
.contracts_list {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 500px;
  background-color: #5f586b;
  border-radius: 10px;
  margin: 150px auto;
  width: 40%;
}

.list-headers {
  position: relative;
  border-bottom: 2px solid rgb(7, 206, 7);
  font-size: 30px;
  padding: 20px;
}

.input-container {
  display: flex;
  align-items: center;
  border: 1px solid #05d1f5;
  border-radius: 20px;
  background-color: #434344;
  overflow: hidden;
  height: 50px;
}

.search-icon {
  color: #05d1f5;
  margin-left: 10px;
}

.search-input {
  flex: 1;
  border: none;
  outline: none;
  padding: 0 10px;
  background-color: transparent;
  color: #f3efef;
}

.search-button {
  border: none;
  background-color: #434344;
  color: #fff;
  padding: 0 15px;
  cursor: pointer;
  height: 100%;
  transition: background-color 0.2s;
}

.search-button:hover {
  background-color: #03a1d4;
}

.data-list {
  margin-top: 20px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}



.data-name {
  font-weight: bold;
  color: #d8f007;
  font-size: 25px;
}



.action-button {
  padding: 5px 10px;
  border: none;
  background-color: #05d1f5;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.action-button:hover {
  background-color: #03a1d4;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.pagination button{
  padding: 5px 10px;
  margin: 0 5px;
  border: none;
  background-color: #0aade4;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  }
 .page-number {
  padding: 5px 10px;
  margin: 0 5px;
  border: none;
  background-color: #035e7c;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.pagination button[disabled], .pagination button[disabled]:hover {
  background-color: #ccc;
  cursor: not-allowed;
}

.page-number.active {
  background-color: #05d1f5;
}

@media (max-width: 768px) {
  .contracts_list {
    width: 100%;
  }
  .search-input {
    width: 94%;
  }
  .search-text {
    display: none;
  }
}
</style>
