import Vue from 'vue';
import Vuex from 'vuex';
import detectEthereumProvider from '@metamask/detect-provider';
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.localStorage
})],
  state: {
    // 在这里定义你的状态
    walletAddress: localStorage.getItem('walletAddress') || null,
    pvpContract: null,
    isVisible: null,
    chainId: '0x1'||null,
    network: {
      chainId: "",
      name: "",
      symbol: "",
      avatar: "",
      contract:"",
      tokens:[]
    },
  },
  mutations: {
    //
    setWalletAddress(state, address) {
      state.walletAddress = address;
    }
,
    setPvpContract(state, address){
      state.pvpContract = address;
    }
    ,
    //传递值，用于隐藏显示pk状态
    setIsVisible(state,iShow){
      state.isVisible = iShow;
    },
    setChainId(state,chainId){
      state.chainId = chainId;
    },

    setNetwork(state,network){
      state.network = network;
    }
  },
  actions: {
    async  connectWallet({ commit }) {
       
        const provider = await detectEthereumProvider();
        if (provider) {
          try {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            localStorage.setItem('walletAddress', accounts[0]);
            commit('setWalletAddress', accounts[0]);
          } catch (error) {
            console.error("connect error");
          }
        } else {
          console.error("no MetaMask!");
        }
    }
    ,
    
    async disconnectWallet({commit}){
      try {
        await window.ethereum.request({
          method: 'wallet_revokePermissions',
          params: [{ eth_accounts: {} }],
        });
        localStorage.removeItem('walletAddress');
        commit('setWalletAddress', null);
      } catch (error) {
        console.error("disconnect error");
      }
    },
    setPvpToken({ commit }, pvpContract) {
      commit('setPvpContract', pvpContract);
    }
    ,
    setVisible({ commit }, iShow) {
      commit('setIsVisible', iShow);
    },
    setChainId({ commit }, chainId) {
      commit('setChainId', chainId);
    }
    ,
    setWalletAddress({ commit }, walletAddress) {
      commit('setWalletAddress', walletAddress);
    },

    setNetwork({ commit },network){
      commit('setNetwork', network);
    }
  },
  getters: {
    isWalletConnected: (state) => !!state.walletAddress,
    walletAddress: (state) => state.walletAddress,
    pvpContract: (state) => state.pvpContract,
    isVisible: (state) => state.isVisible,
    chainId: (state) => state.chainId,
    network: (state) => state.network,
  }
});
