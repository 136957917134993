<template>
  <header :class="['header', { 'transparent-header': isHome }]">
    <div class="header-content">
      <nav v-if="isLargeScreen">
        <ul>
          <li v-for="item in navItems" :key="item.id">
            <router-link :to="item.link">
              <component :is="item.icon" />
              <span class="nav-text">{{ $t(item.text) }}</span>
            </router-link>
          </li>
        </ul>
      </nav>
      <div class="right-side-content">
         
      <!-- Language switch -->
      <div class="language-switch">
        <webLanguage @click="toggleLanguageDropdown" class="language-icon" />
        <ul v-show="showLanguageDropdown" class="language-dropdown">
          <li v-for="lang in availableLanguages" :key="lang.code" @click="changeLanguage(lang.code)">
            {{ lang.name }}
          </li>
        </ul>
       
      
      </div>
      <mdiAlphaX @click="toTwitter" class="X-icon" />
       
      <!-- network switch -->
      <div class="network-switch" >
        <div class="network-switch-wrapper" @click="toggleNetworkDropdown">
          <div class="icon-circle">
            <img :src="selectedNetworkAvatar"  class="network-icon" />
          </div>
          <div class="address-info">
            <span class="wallet-address">{{ selectedNetworkName }}</span>
            <ChevronDown class="chevron-down" />
          </div>
        </div>
        <ul v-show="showNetworkDropdown" class="network-dropdown">
            <li v-for="network in networks" :key="network.chainId" @click="switchNetwork(network)">
              <img :src="network.avatar" alt="network.name" class="network-avatar" /> {{ network.name }}
            </li>
        </ul>
        </div>
    <!-- Wallet container -->
    <div class="wallet-container" @mouseenter="showDropdown = true,showLanguageDropdown=false,showNetworkDropdown=false" @mouseleave="showDropdown = false">
      <button v-if="!isWalletConnected" class="wallet-button" @click="handleConnectWallet">{{ $t('connect') }}</button>
      <div v-else class="wallet-info">
        <div class="wallet-address-wrapper" @click="toggleDropdown">
          <div class="icon-circle">
            <WalletBifold class="walletBifold" />
          </div>
          <div class="address-info">
            <span class="wallet-address">{{ walletAddressShort }}</span>
            <ChevronDown class="chevron-down" />
          </div>
        </div>
        <div v-if="showDropdown" class="dropdown-menu">
          <div class="dropdown-item" @click="handleDisconnect">
            {{ $t('disconnect') }}
            <Logout class="dropdown-icon" />
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>
  </header>
</template>

<script>
import ChevronDown from 'vue-material-design-icons/ChevronDown.vue';
import Logout from 'vue-material-design-icons/Logout.vue';
import WalletBifold from 'vue-material-design-icons/WalletBifold.vue';
import mdiAlphaX from 'vue-material-design-icons/AlphaXCircle.vue';
import webLanguage from 'vue-material-design-icons/Web.vue';
import { mapActions, mapGetters } from 'vuex';
import networksData from '@/json/networks.json';


export default {
  name: 'Headers',
  components: {
    ChevronDown,
    Logout,
    WalletBifold,
    webLanguage,
    mdiAlphaX
  },
  props: {
    isLargeScreen: Boolean,
    isHome: Boolean,
  },
  data() {
    return {
      showDropdown: false,
      showLanguageDropdown: false,
      showNetworkDropdown: false,
      navItems: [
        { id: 1, text: 'Home', link: '/' },
        //{ id: 2, text: 'Pvp', link: '/pvp' },
       // { id: 3, text: 'Cas', link: '/Contracts'},
        { id: 4, text: 'AirDrop', link: '/AirDrop'},
      ],
      availableLanguages: [
        { code: 'en', name: 'English' },
        { code: 'zh', name: '简体中文' },
      ],
      networks:networksData.map(network => ({
        ...network,
       avatar: require(`@/images/${network.avatar}`)
      }))
      
     
    };
  },
  computed: {
    ...mapGetters(['isWalletConnected', 'walletAddress','chainId','network']),
   
    walletAddressShort() {
      const tWalletAddress= this.walletAddress;
      if (!tWalletAddress) return '';
      const start = tWalletAddress.slice(0, 2);
      const end = tWalletAddress.slice(-4);
      return `${start}*${end}`;
    },
    selectedNetworkAvatar() {
      const network = this.networks.find(n => n.chainId === this.chainId);
      return network.avatar;
    },
    selectedNetworkName() {
      const network = this.networks.find(n => n.chainId === this.chainId);
      return network.symbol;
    }
  },
  mounted() {
    const preferredLanguage = localStorage.getItem('preferredLanguage');
    if (preferredLanguage) {
      this.$i18n.locale = preferredLanguage;
    }
    
    const selectedNetworkId = this.chainId;
    if (!selectedNetworkId) {
      this.setChainId(String('0x1'));
      this.setNetwork(this.networks.find(n => n.chainId === '0x1'));
    }

    if (window.ethereum) {
      window.ethereum.on('accountsChanged', (accounts) => {
      if (accounts.length > 0) {
            this.updateWalletAddress(accounts[0]);
      } else {
        this.handleDisconnect(); // 处理断开连接情况
      }
    });
  }

  },
  methods: {
    ...mapActions(['connectWallet','disconnectWallet','setChainId','setWalletAddress','setNetwork','setPvpToken']),
   
    async syncNetwork(networkId) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: networkId}],
        });
       
        this.setChainId(networkId);
        this.setNetwork(this.networks.find(n => n.chainId === networkId));
        if(this.network.tokens.length==0){
          this.setPvpToken(null);
        }
      } catch (error) {
        if (error.code === 4001) {
         
        } else {
          console.error("change network error", error);
        }
      }
    }
    ,
    async switchNetwork(network) {
      await this.syncNetwork(network.chainId);
      this.showNetworkDropdown =false;
    },
    handleDisconnect() {
      this.disconnectWallet();
      this.showDropdown = false;
    },
    async handleConnectWallet() {
      await this.connectWallet();
      const selectedNetworkId = this.chainId;
      this.syncNetwork(selectedNetworkId?selectedNetworkId:'0x1');
      this.showDropdown = false;
    },
    updateWalletAddress(address) {
    
    if (this.isWalletConnected) {
      this.setWalletAddress( address);     
    }
  },
  toTwitter(){
    window.open("https://x.com/home", '_blank');
  }
  ,
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
      this.showLanguageDropdown = false;
      this.showNetworkDropdown ==false;
    },
    toggleLanguageDropdown() {
      this.showLanguageDropdown = !this.showLanguageDropdown;
      this.showDropdown=false;
      this.showNetworkDropdown =false;
    },
    toggleNetworkDropdown() {
      this.showNetworkDropdown = !this.showNetworkDropdown;
      this.showDropdown = false;
      this.showLanguageDropdown = false;
    },
    changeLanguage(langCode) {
      this.$i18n.locale = langCode;
      localStorage.setItem('preferredLanguage', langCode);
      this.showLanguageDropdown = false;
    },
  }
};
</script>

<style scoped>
.header {
  background-color: #1B152B;
  padding: 10px 0;
  height: 45px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
}

.transparent-header {
  background-color: transparent !important;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  height: 100%;
  margin-left: 300px;
}

nav ul li {
  display: flex;
  align-items: center;
}

nav ul li a {
  color: #00FF00;
  text-decoration: none;
  font-size: 1em;
  padding: 0 10px;
  font-family: 'Press Start 2P', cursive;
}

.wallet-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 100%;
}

.wallet-button {
  background-color: #05756ca6;
  color: #FFFFFF;
  border: none;
  border-radius: 15px;
  width: 50%;
  height: 40px;
  font-family: 'Press Start 2P', cursive;
  font-size: 1em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wallet-info {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  position: relative;
  justify-content: center;
  font-size: 1em;
}

.wallet-address-wrapper {
  display: flex;
  align-items: center;
  height: 35px;
  border: 1px solid #00FF00;
  border-radius: 20px;
  padding: 0 5px;
  cursor: pointer;
}

.icon-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border: 1px solid #00FF00;
  border-radius: 50%;
  margin-left: -6px;
}

.address-info {
  display: flex;
  align-items: center;
  padding: 5px;
}

.walletBifold {
  font-size: 1.2em;
  color: #0552c7;
  margin-bottom: 5px;
}

.wallet-address {
  color: #00FF00;
  font-family: 'Press Start 2P', cursive;
  font-size: 1em;
}

.nav-text {
  line-height: 45px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  margin-top: 10px;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  width: 150px;
  z-index: 2000;
  border-radius: 5px;
}

.dropdown-item {
  color: #000;
  background-color: #fff;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
}

.dropdown-icon {
  color: #000;
  margin-left: 10px;
}

.chevron-down {
  font-size: 2em; 
  margin-left: 5px;
  color: #4f6596;
  display: flex;
  align-items: center; 
  justify-content: center; 
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .wallet-container{
    width: 100px;
    height: 100%;
    margin-right: 10px;  
  }
  .wallet-button {
  width: 80%;
  height: 40px;

}
  .wallet-address {
    display: none; 
  }
  .wallet-info {
    justify-content: flex-end;
  }
  .dropdown-menu {
    left: auto; 
    right: 0;
  }
  .right-side-content {
    flex: 1; 
    justify-content: flex-end;
  }
  .network-switch-wrapper {
  margin-left: 10px;
}
}

.language-switch {
  position: relative;
  display: inline-block;
}

.language-icon {
  cursor: pointer;
  color: #fff;
  font-size: 1.8em;
}
.X-icon{
  cursor: pointer;
  color: #fff;
  font-size: 1.9em;
  margin-bottom: 5px;
  margin-left: 15px;
}
.language-dropdown {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1000;
  list-style-type: none; 
  padding: 0;
}

.language-dropdown.active {
  display: block;
}

.language-dropdown li {
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
}

.language-dropdown li:hover {
  background-color: #f1f1f1;
}
.right-side-content {
  display: flex;
  align-items: center;
}
.network-switch {
  display: flex;
  widows: 100px;
  height: 100%;
  position: relative;

}

.network-dropdown {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 140px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1000;
  list-style-type: none;
  padding: 0;
}
.network-dropdown.active {
  display: block;
}
.network-dropdown li {
  display: flex; 
  align-items: center;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
  margin-left: 15px;
}
.network-avatar {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
.network-icon{
  width: 33px;
  height: 33px;
}
.network-dropdown li:hover {
  background-color: #f1f1f1;
}
.network-switch-wrapper {
  display: flex;
  align-items: center;
  height: 35px;
  border: 1px solid #00FF00; 
  border-radius: 20px;
  padding: 0 5px;
  cursor: pointer;
  margin-left: 20px;
}
</style>
