<template>
  <div class="pair-info" v-if="isVisible">
    <mdiClose class="close-icon" @click="hide" />
    <!-- 对向进度条 -->
    <div class="progress-bar-container">
      <div class="progress-bar">
        <div class="left-bar" :style="{ width: leftWidth + '%' }">{{leftScore}}</div>
        <div class="right-bar" :style="{ width: rightWidth + '%' }">{{rightScore}}</div>
      </div>
    </div>
    <div class="side-pvp">
          <h2 v-if="!iStart"  class="timer">{{$t('not start')}}</h2>
          <h2 v-if="iStart&&iEnd"  class="timer">{{$t('already over')}}</h2>
          <h2 v-if="iStart&&!iEnd"  class="timer">{{ this.time }}</h2>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import mdiClose from 'vue-material-design-icons/Close.vue';
import web3j from '@/js/web3j.js';
export default {
  name: 'PairInfo',
  components: { mdiClose },
  data() {
    return {
      time: 0,
      interval: null,
      leftScore: 0, // 左侧默认分数
      rightScore: 0, // 右侧默认分数
      iStart: false,
      iEnd: false
    };
  },
  computed: {
    ...mapGetters(['pvpContract', 'isVisible']),
    leftWidth() { 
      const leftScore = Number(this.leftScore);
      const rightScore = Number(this.rightScore);
      if(leftScore === 0 && rightScore === 0){
        return 50;
      }
      if(leftScore === 0 && rightScore!== 0){
        return 0;
      }
      if((leftScore !== 0 && rightScore!== 0)||(leftScore !== 0 && rightScore=== 0)){
        return (leftScore / (leftScore + rightScore)) * 100;
      } 
  }, 
    rightWidth() { 
      const leftScore = Number(this.leftScore);
      const rightScore = Number(this.rightScore);
    if(leftScore === 0 && rightScore === 0){
        return 50;
      }
      if(leftScore !== 0 && rightScore=== 0){
        return 0;
      }
      if((leftScore != 0 && rightScore!= 0)||(leftScore === 0 && rightScore!== 0)){
        return (rightScore / (leftScore + rightScore)) * 100;
      } 
    
  },
  },
  methods: {
    ...mapActions(['setVisible']),
    async initScore(){
      try{
        if(this.pvpContract){
          const reserves= await web3j.getReserves(this.pvpContract.contract);
          this.leftScore=reserves.red;
          this.rightScore=reserves.blue;
          this.time =reserves.endBlock;
          this. iStart= await web3j.getIstart(this.pvpContract.contract);
          this.iEnd= await web3j.getIsEnd(this.pvpContract.contract);
        }else{
          this.leftScore=0;
          this.rightScore=0;
          this. iStart= false;
        }
      }catch(error){

      }
      
    },
    hide() {
      this.setVisible(false);
    },
  },

  mounted() {
    this.initScore();
   this.scoreInterval = setInterval(() => {
    this.initScore();
  }, 3000);
  },
  beforeDestroy() {
    clearInterval(this.scoreInterval);
  },
};
</script>


<style scoped>
.pair-info {
  position: relative;
  display: flex;
  flex-direction: column; /* 使子元素垂直排列 */
  align-items: center;
  background-color: #e2dfdf;
  border-radius: 10px;
  padding: 10px;
}

.close-icon {
  position: absolute; /* 设置为绝对定位 */
  top: 10px; /* 距离顶部10px */
  right: 10px; /* 距离右侧10px */
  cursor: pointer; /* 鼠标指针变为手型 */
  font-size: 25px;
}
.timer {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 30px;
  color: rgb(243, 73, 6);
  background-color: rgba(157, 129, 184, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
}

.side-pvp {
  position: absolute;
  bottom: 0; 
  width: 100%;
  height: 88%;
  background-image: url('../images/pvp.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  display: flex;
  justify-content: center;
  align-items: flex-start; 
}

.progress-bar-container {
  width: 103%;
  position: relative;
  margin-top: 38px; /* 上移，使其贴合 side-pvp */
 
}

.progress-bar {
  display: flex;
  height: 20px;
  overflow: hidden;
}

.left-bar {
  background: linear-gradient(to right, #ff4d4d, rgba(255, 77, 77, 0.3)); /* 左侧渐变红, 中间变为浅红 */
  height: 100%;
  transition: width 0.3s;
  
}
.right-bar {
  background: linear-gradient(to left, #4d4dff, rgba(77, 77, 255, 0.3));
  height: 100%;
  transition: width 0.3s;
  display: flex;
  justify-content: flex-end; /* 将内容推到最右侧 */
  align-items: center; /* 垂直居中 */
  padding-right: 5px; /* 增加右侧内边距 */
  color: white;
}




@media (max-width: 768px) {
  .pair-info {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .side {
    width: 100%;
    height: 30%;
    text-align: center; /* 让文本居中 */
  }
  .side-pvp {
  background-image: url('../images/pvp_v.png');
  height: 88%; /* 高度为父级的70% */
}
.progress-bar-container {
  width: 100%;
  margin-top: 48px; /* 上移，使其贴合 side-pvp */
}
}
</style>
