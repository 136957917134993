import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/Home.vue';
import Pvp from '@/views/Pvp.vue';
import Create from '@/views/Create.vue';
import PairInfo from '@/views/PairInfo.vue';
import Contracts from '@/views/Contracts.vue';
import Airdrop from '@/views/Airdrop.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: { isHome: true }
    },
    {
      path: '/pvp',
      name: 'Pvp',
      component: Pvp
    },
    {
      path: '/create',
      name: 'Create',
      component: Create
    },
    {
      path: '/pairInfo',
      name: 'PairInfo',
      component: PairInfo
    },
    {
      path: '/Contracts',
      name: 'Contracts',
      component: Contracts
    },
    {
      path: '/Airdrop',
      name: 'Airdrop',
      component: Airdrop
    }
  ]
});

router.afterEach((to, from) => {
  if (to.name === 'Home') {
    router.app.$emit('route-change');
  }
});

export default router;
