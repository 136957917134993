<template>
  <div id="app">
    <Headers
      :isLargeScreen="isLargeScreen"
      :isHome="isHome"
      :connectWallet="connectWallet"
    />
    <main :style="{ paddingBottom: isHome || isLargeScreen ? '0' : '70px' }">
      <router-view @route-change="checkIsHome"></router-view>
    </main>
    <Floors
      v-if="!isHome"
      :isLargeScreen="isLargeScreen"
    />
  </div>
</template>

<script>
import Headers from './views/Headers.vue';
import Floors from './views/Floors.vue';
import detectEthereumProvider from '@metamask/detect-provider';
export default {
  name: 'App',
  components: {
    Headers,
    Floors
  },
  data() {
    return {
      isLargeScreen: window.innerWidth > 768,
      isHome: this.$route.path === '/',
    };
  },
  mounted() {
    window.addEventListener('resize', this.checkScreenSize);
    this.checkScreenSize(); // Check initial screen size
    this.$router.beforeEach((to, from, next) => {
      this.isHome = to.path === '/';
      next();
    });
  },
  methods: {
    
    checkScreenSize() {
      this.isLargeScreen = window.innerWidth > 768;
    },
    checkIsHome() {
      this.isHome = this.$route.path === '/';
    },
    async connectWallet() {
      const provider = await detectEthereumProvider();
      if (provider) {
        try {
          const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
          localStorage.setItem('walletAddress', accounts[0]);
        } catch (error) {
          console.error("connect error");
        }
      } else {
        console.error("no MetaMask!");
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenSize);
  }
};
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  font-family: 'Press Start 2P', cursive;
  background-color: #1B152B;
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>
