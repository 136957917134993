import Web3 from 'web3';
import factory from '../abi/factory.json';
import pair from '../abi/pair.json';
import ierc20 from '../abi/ierc20.json';
const web3 = new Web3(window.web3.currentProvider);

const factoryContract = (contractAddress) => {

    return new web3.eth.Contract(factory, contractAddress);
  };
const pairContract = (contractAddress) => {
    return new web3.eth.Contract(pair, contractAddress);
  };
const IERC20 = (contractAddress) => {
    return new web3.eth.Contract(ierc20, contractAddress);
  };
export default {
    async allPairsLength(contractAddress) {
        try {
          const contract = factoryContract(contractAddress);
          return await contract.methods.allPairsLength().call();
        } catch (error) {
          return null;
        }
      },
      async getPair(contractAddress, tokenAddress) {
        try {
          const contract = factoryContract(contractAddress);
          const tokens= await contract.methods.getPair(tokenAddress).call();
          if(tokens==='0x0000000000000000000000000000000000000000'){
            return null;
          }
          return tokens;
        } catch (error) {
          return null;
        }
      },

  // 创建新的 Pair
  async createPair(contractAddress, tokenAddress,  _type, fromAddress) {
    try {
      const contract = factoryContract(contractAddress);
      const tx = await contract.methods.createPair(tokenAddress,  _type).send({
        from: fromAddress,
      });
      return tx;
    } catch (error) {
      return null;
    }
  },



  async getFactoryAddress(contractAddress) {
    try{
    const contract = pairContract(contractAddress);
    return await contract.methods.factory().call();
  } catch (error) {
    return null;
  }
  },

  async  getReserves(contractAddress) {
    try{
    const contract = pairContract(contractAddress);
    const tokenContract = await contract.methods.token().call();
    const decimals_= await this.decimals(tokenContract)||18;
    let reserves= await contract.methods.getReserves().call();
    const red = (reserves.red).toString();
    const blue = (reserves.blue).toString();
    let formattedRed = red !== 0n ? (Number(red) / Number(10**Number(decimals_.toString()))) : 0;
    let formattedBlue = blue !== 0n ? (Number(blue) / Number(10**Number(decimals_.toString()))) : 0;
    reserves.red = formattedRed !== 0n ? (parseFloat(formattedRed.toString()) / 1).toFixed(6).replace(/\.?0+$/, "") : "0";
    reserves.blue  = formattedBlue !== 0n ? (parseFloat(formattedBlue.toString()) / 1).toFixed(6).replace(/\.?0+$/, "") : "0";
    reserves.endBlock=Number(reserves.endBlock);
    return reserves;
  } catch (error) {
    console.error('Error fetching token all pairs:', error);
    const red = 0;
    const  blue  = 0;
    const endBlock=0;
    return {red,blue,endBlock};
  }
  },

  async joinRed(contractAddress, amount, fromAddress) {
    try{
    const contract = pairContract(contractAddress);
    const tokenContract = await contract.methods.token().call();
    const decimals_=await this.decimals(tokenContract);
    if (amount !== undefined && decimals_ !== undefined) {
      const adjustedAmount = this.convertToBigInt(amount, decimals_);
      return await contract.methods.joinRed(adjustedAmount).send({ from: fromAddress });
    }
  } catch (error) {
    return null;
  }
  },

  async joinBlue(contractAddress, amount, fromAddress) {
  try{
     const contract = pairContract(contractAddress);
      const tokenContract = await contract.methods.token().call();
      const decimals_=await this.decimals(tokenContract);
    if (amount !== undefined && decimals_ !== undefined) {
      const adjustedAmount = this.convertToBigInt(amount, decimals_);
      return await contract.methods.joinBlue(adjustedAmount).send({ from: fromAddress });
    }
  } catch (error) {
    console.error('Error fetching token all pairs:', error);
    return null;
  }
  },
  async getReward(contractAddress, fromAddress) {
    try{
    const contract = pairContract(contractAddress);
    return await contract.methods.getReward().send({ from: fromAddress });
  } catch (error) {
    return null;

  }
  },
  async getIsEnd(contractAddress) {
    try{
    const contract = pairContract(contractAddress);
    return await contract.methods.getIsEnd().call();
  } catch (error) {
    return null;

  }
  },
  async getIstart(contractAddress) {
    try{
    const contract = pairContract(contractAddress);
    return await contract.methods.getIsStarted().call();
  } catch (error) {
    return null;

  }
  },
  async getType(contractAddress) {
    try{
    const contract = pairContract(contractAddress);
    return await contract.methods.getType().call();
  } catch (error) {
    return null;

  }
  },

  async getJoinAmount(contractAddress,uAddress) {
    try{
    const contract = pairContract(contractAddress);
    return await contract.methods.getJoinAmount(uAddress).call();
  } catch (error) {
    return null;

  }
  },
  async getRewardClaimed(contractAddress,uAddress){
    try{
      const contract = pairContract(contractAddress);
      return await contract.methods.getRewardClaimed(uAddress).call();
    } catch (error) {
      return true;
    }
  }
  ,
  async redVictory(contractAddress){
    try{
      const contract = pairContract(contractAddress);
      return await contract.methods.redVictory().call();
    } catch (error) {
      return false;
    }
  },
  async getToken(contractAddress) {
    try{
    const contract = pairContract(contractAddress);
    return await contract.methods.token().call();
  } catch (error) {
    return null;

  }
  },
  async decimals(contractAddress) {
    try{
    const contract = IERC20(contractAddress);
    const decimals= await contract.methods.decimals().call();
    return decimals;
  } catch (error) {
    return 18;
  }
  },
  async balanceOf(contractAddress,uAddress) {
    try{
    const contract = IERC20(contractAddress);
    const decimals_= await this.decimals(contractAddress);
    const balance= await contract.methods.balanceOf(uAddress).call();
    const formattedBalance = this.formatBalance(balance, 10n**decimals_);
    return formattedBalance;
  } catch (error) {
    console.error('Error fetching token balance:', error);
    return 0;
  }
  },

  async approve(contractAddress,pairAddress,uAddress){
    try{
    const contract = IERC20(contractAddress);
    return await contract.methods.approve(pairAddress,1000000000000000000000000000000000000000000n).send({ from: uAddress });
    }catch (error) {
    return null;
    }
  }
  ,
  async getName(contractAddress){
    try{
    const contract = IERC20(contractAddress);
    return await contract.methods.name().call();
    }catch (error) {
    return null;
    }
  },
  async allowance(contractAddress,pairAddress,uAddress){
    try{
      const contract = IERC20(contractAddress);
    return await contract.methods.allowance(uAddress,pairAddress).call();
    }
    catch (error) {
    return 0;
    }
    
  }
  ,
   formatBalance (balance, decimals){
    const integerPart = balance / decimals;
    const fractionalPart = balance % decimals;

    let fractionalString = (Number(fractionalPart) / Number(decimals))
      .toFixed(6)
      .slice(2);
    fractionalString = fractionalString.replace(/0+$/, '');
    return fractionalString ? `${integerPart}.${fractionalString}` : `${integerPart}`;
  },
   convertToBigInt(amount, decimals) {
    if(amount==0||amount==undefined||!amount){
      return 0;
    }
     // 通过将小数扩大至整数
  const multiplier = BigInt(10) ** BigInt(decimals);

  // 将 amount 乘以 multiplier，将结果转换为 BigInt
  const adjustedAmount = BigInt(Math.round(parseFloat(amount) * Number(multiplier)));

  return adjustedAmount;
  }
  ,
  async sendEthToAddress(from,to){

    var message = {
      from: from,
      to: to,
      value: 0,
      data: '0x1249c58b',
    }

    try {
      const gasPrice = await web3.eth.getGasPrice();
      const gasLimit = await web3.eth.estimateGas(message);
      message.gasPrice = gasPrice;
      message.gas = gasLimit;
      await web3.eth.sendTransaction(message);

    } catch (error) {
     
    }
  }

};
