import axios from '../http.js' // 导入http中创建的axios实例

const order = {
    // 查询所有对
    getPairs(params) {
      return axios.get('/pvp/api/getPairs', {
          params: params,
      })
  },
      // 查询详情
      getPairsInfo(params) {
        return axios.get('/pvp/api/getPairsInfo', {
            params: params,
        })
    },

}


export default order
