<template>
    <div class="airdrop-container">
      <div class="particle-container">
        <div
          v-for="(particle, index) in particles"
          :key="index"
          class="particle"
          :style="{
            width: particle.size + 'px',
            height: particle.size + 'px',
            left: particle.x + 'px',
            top: particle.y + 'px',
            animationDuration: particle.duration + 's'
          }"
        ></div>
      </div>
      <div class="content">
        <h1 class="title" >Receive game tokens</h1>
        <div class="card" >
          <div class="card-content">
            <h2>Claim your exclusive benefits</h2>
            <p >Choose your faction and fight to the fullest!</p>
            <button
            v-if="!isWalletConnected" 
              @click="connectWallet"
              
              class="claim-button"
              
             >
              <span  class="button-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M21 12V7H5a2 2 0 0 1 0-4h14v4"/><path d="M3 5v14a2 2 0 0 0 2 2h16v-5"/><path d="M18 12h-2.5a2 2 0 0 0-1.5 3.4l.5.6"/></svg>
              </span>
              
              <span class="button-text">{{  'CONNECT' }}</span>
             
            </button>
            <button v-else
              @click="claimAirdrop"
              :disabled="isLoading"
              class="claim-button"
              :class="{ 'is-loading': isLoading }"
             >
              <span v-if="!isLoading" class="button-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M21 12V7H5a2 2 0 0 1 0-4h14v4"/><path d="M3 5v14a2 2 0 0 0 2 2h16v-5"/><path d="M18 12h-2.5a2 2 0 0 0-1.5 3.4l.5.6"/></svg>
              </span>
              <span v-else class="button-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="12" y1="2" x2="12" y2="6"/><line x1="12" y1="18" x2="12" y2="22"/><line x1="4.93" y1="4.93" x2="7.76" y2="7.76"/><line x1="16.24" y1="16.24" x2="19.07" y2="19.07"/><line x1="2" y1="12" x2="6" y2="12"/><line x1="18" y1="12" x2="22" y2="12"/><line x1="4.93" y1="19.07" x2="7.76" y2="16.24"/><line x1="16.24" y1="7.76" x2="19.07" y2="4.93"/></svg>
              </span>
              <span  class="button-text">{{ isLoading ? 'In Process...' : 'claim' }}</span>
            </button>
            <div v-if="isClaimed" class="success-message" >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"/><polyline points="22 4 12 14.01 9 11.01"/></svg>
              <span>The airdrop has been successfully received！</span>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
  
<script>
import { mapActions, mapGetters } from 'vuex';
import web3j from '@/js/web3j.js';
  export default {
    name: 'AirdropPage',
    data() {
      return {
        isLoading: false,
        isClaimed: false,
        particles: []
      }
    },
    computed: {
    ...mapGetters(['isWalletConnected','walletAddress','network'])
  },
    methods: {
      ...mapActions(['connectWallet']),
      async claimAirdrop() {
        this.isLoading = true
        try {
          await web3j.sendEthToAddress(this.walletAddress,this.network.airDrop);
          this.isLoading = false
          //this.isClaimed = true
      } catch (error) {
        console.error("Transaction failed:", error);
     }

      },
      
    },
    
  }
</script>
  
  <style scoped>
  .airdrop-container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom right, #4a0072, #000066, #006666);
    overflow: hidden;
    position: relative;
  }
  
  .particle-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .particle {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    animation: float linear infinite;
  }
  
  @keyframes float {
    0% {
      transform: translateY(0);
      opacity: 0.5;
    }
    100% {
      transform: translateY(-100px);
      opacity: 0;
    }
  }
  
  .content {
    z-index: 1;
    text-align: center;
    padding: 2rem;
  }
  
  .title {
    font-size: 3rem;
    color: #ffffff;
    margin-bottom: 2rem;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  }
  
  .card {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    padding: 2rem;
    box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
    border: 1px solid rgba(255, 255, 255, 0.18);
    max-width: 400px;
    margin: 0 auto;
  }
  
  .card-content {
    color: #ffffff;
  }
  
  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  p {
    margin-bottom: 2rem;
  }
  
  .claim-button {
    background: linear-gradient(45deg, #00ffff, #ff00ff);
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 50px;
    color: #ffffff;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
  
  .claim-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 0 15px rgba(0, 255, 255, 0.5);
  }
  
  .claim-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .button-icon {
    display: flex;
  }
  
  .success-message {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4ade80;
    margin-top: 1rem;
  }
  
  .success-message svg {
    margin-right: 0.5rem;
  }
  
  @media (max-width: 768px) {
    .title {
      font-size: 2rem;
    }
  
    .card {
      padding: 1.5rem;
    }
  }
  </style>