import axios from 'axios'

import router from '../router'
const isProduction = process.env.NODE_ENV === 'production'; 
axios.defaults.baseURL=isProduction?'/prod-api':'/'
axios.defaults.timeout = 10000
axios.defaults.headers.post['Content-Type'] =
    'application/x-www-form-urlencoded;charset=UTF-8'

/**
 * 提示函数
 *
 */
const tip = (msg) => {
  // this.$message.error(msg)
}

/**
 * 跳转登录页
 *
 */
const toHome = () => {
    router.push({
        path: '/',
    })
}

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status) => {
    // 状态码判断
    switch (status) {
        // 401: 未登录状态，
        case 401:
            tip('登录过期，请重新登录')
            toHome()
            break
            // 403 token过期
            // 清除token并
        case 403:
            tip('登录过期，请重新登录')
            toHome()
            break
            // 404请求不存在
        case 404:
            tip('请求的资源不存在')
            break
        default:
    }
}

// 创建axios实例
var instance = axios.create({
        timeout: 1000 * 12,
    })
    // 设置post请求头
instance.defaults.headers.post['Content-Type'] =
    'application/x-www-form-urlencoded'
    /**
     * 请求拦截器
     * 每次请求前，如果存在token则在请求头中携带token
     */
instance.interceptors.request.use(
    (config) => {
        let address= sessionStorage.getItem('address')
        if (address) {
            config.headers.auth = address
        }

        return config
    },
    (error) => Promise.error(error)
)

// 响应拦截器
instance.interceptors.response.use(
    // 请求成功
    (res) => (res.status === 200 ? Promise.resolve(res) : Promise.reject(res)),
    // 请求失败
    (error) => {
        const { response } = error
        if (response) {
            errorHandle(response.status)
            return Promise.reject(response)
        } else {
            return Promise.reject(error)
        }
    }
)

export default instance
