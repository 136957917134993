<template>
  <div class="Home-box">
    <div class="Home-main">
      <video class="video-background" autoplay muted loop>
        <source src="@/images/pk.mp4" type="video/mp4" />
      </video>
      <div class="start" @click="handleStart">{{ $t('Start') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  mounted() {
    this.$emit('route-change');
    const preferredLanguage = localStorage.getItem('preferredLanguage');
    if (preferredLanguage) {
      this.$i18n.locale = preferredLanguage;
    }
  },
  methods: {
    handleStart() {
      this.isActive = true;
      setTimeout(() => {
        this.isActive = false;
        this.$router.push('/airDrop')
      }, 300);
    }
  }
}
</script>

<style lang="scss" scoped>
.Home-box {
  height: 100vh;
  background: #030747;
  position: relative;
  overflow: hidden;
}

.Home-main {
  height: 100%;
  width: 100%;
  position: relative;
}

.video-background {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.start {
  position: absolute;
  bottom: 5%;
  left: 50%;
  top: 80%;
  transform: translateX(-50%);
  width: 100px;
  height: 60px;
  color: #fff;
  font-size: 25px;
  background: #238b0a;
  border-radius: 20px;
  display: flex; /* 使用 flexbox 来居中 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  line-height: 30px; /* 如果不使用 flexbox，可以通过 line-height 来垂直居中 */
  text-align: center;
  cursor: pointer;
  animation: glow 3s infinite;
}


@keyframes glow {
  0% {
    box-shadow: 0 0 10px #238b0a, 0 0 20px #238b0a, 0 0 30px #238b0a;
  }
  50% {
    box-shadow: 0 0 20px #238b0a, 0 0 40px #238b0a, 0 0 60px #238b0a;
  }
  100% {
    box-shadow: 0 0 10px #238b0a, 0 0 20px #238b0a, 0 0 30px #238b0a;
  }
}
</style>
