import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './locales/en.json';
import zh from './locales/zh.json';

Vue.use(VueI18n);

const messages = {
  en,
  zh
};

const i18n = new VueI18n({
  locale: 'en', // 默认语言
  fallbackLocale: 'en', // 回退语言
  messages
});

export default i18n;
