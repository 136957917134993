<template>
  <footer class="footer">
    <nav v-if="!isLargeScreen">
      <ul>
        <li v-for="item in navItems" :key="item.id">
          <router-link :to="item.link">
            <i :class="item.icon"></i>
            <span class="nav-text">{{ item.text }}</span>
          </router-link>
        </li>
      </ul>
    </nav>
  </footer>
</template>

<script>
export default {
  name: 'Floors',
  props: {
    walletAddress: String,
    connectWallet: Function,
    isLargeScreen: Boolean,
  },
  data() {
    return {
      navItems: [
        { id: 1, text: 'HOME', link: '/', icon: 'fas fa-home' },
        //{ id: 2, text: 'PVP', link: '/pvp', icon: 'fas fa-info-circle' },
        //{ id: 3, text: 'CAS', link: '/Contracts', icon: 'fas fa-microchip' },
        { id: 4, text: 'AirDrop', link: '/AirDrop'},
      ],
    };
  },
};
</script>

<style scoped>
.footer {
  background-color: #1B152B;
  padding: 10px 0;
  width: 100%;
  height: 50px;
  position: fixed;
  bottom: 0;
  z-index: 1000;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
}

nav ul li {
  display: flex;
  align-items: center;
}

nav ul li a {
  color: #00FF00;
  text-decoration: none;
  font-size: 1em;
  padding: 0 10px;
  font-family: 'Press Start 2P', cursive;
}

nav ul li a i {
  margin-right: 5px;
}

.nav-text {
  line-height: 45px;
}

@media (max-width: 768px) {

  .footer {
  background-color: #140101;
 
}
}
</style>
