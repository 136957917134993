<template>
  <div class="form-container">
    <div class="input-group">
      <label class="input-label">{{$t('Contract')}}</label>
      <input type="text" v-model="contractAddress" placeholder="contract" class="input-box" />
    </div>
    <div class="switch-group">
      <label class="switch-label">{{$t('Endless')}}</label>
      <label class="switch">
        <input
          type="checkbox"
          class="switch-input"
          v-model="endlessMode"
        />
        <span class="slider"></span>
      </label>
    
  </div>
    <div class="button-group">
      <button v-if="isWalletConnected" class="submit-button" @click="createPvp">{{$t('create')}}</button>
      <button v-else @click="connectWallet" class="submit-button">Connect</button>
    </div>
  </div>
</template>

<script>
import web3j from '@/js/web3j.js';
import { mapActions,mapGetters } from 'vuex';
export default {
  name: 'Create',
  data() {
    return {
      endlessMode: true, 
      contractAddress: '',
    };
  },
  computed: {
    ...mapGetters(['network',"walletAddress",'isWalletConnected']),
  },
  methods: {
    ...mapActions(['connectWallet']),
   
    async  createPvp(){
     if(this.network){
      try{
        await web3j.createPair(this.network.contract,this.contractAddress,this.endlessMode?1:0,this.walletAddress);
      }catch(error){
        console.log(error);
      }
    
     }
 
    }
  },
}
</script>

<style scoped>
.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  padding: 20px;
  margin: auto;
  background-color: #f9f9f9;
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.input-group,
.switch-group {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

.input-label,
.switch-label {
  width: 80px; /* 保持对齐 */
  margin-right: 10px;
  font-weight: bold;
}

.input-box {
  flex: 1;
  height: 50px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
}

.button-group {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.submit-button {
  width: 70%;
  padding: 15px;
  background: linear-gradient(90deg, #f36d0d, #210b9b 70%);
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

.switch-input:checked + .slider {
  background-color: #4caf50;
  box-shadow: 0px 0px 15px rgba(76, 175, 80, 0.5);
}

.switch-input:checked + .slider:before {
  transform: translateX(26px);
}
@media (max-width: 768px) {
  .submit-button {
    width: 80%;

}
 
}
</style>
